<template>
  <h4 class="text-decoration-underline">Easy Troop Production Speed Ups</h4>
  Basically when you train a certain amount of troops you get an achievement reward that gives you speed ups. So all you
  need is resources.

  <ol class="mt-3">
    <li>Go to the armory to train whatever troop.</li>
    <li>Start training tier 1 units.</li>
    <li>Immediately go back in armory and cancel training with the small red '-' button.</li>
  </ol>

  This will give you half your resources back but every so often you get speed ups. You can do this for all 4 types of
  units. So you're basically trading resources for speed ups at a great rate.
</template>

<script>
export default {
  name: "EasyTroopSpeedUps"
}
</script>

<style scoped>

</style>